import styles from './Auth.less';
import TMA_LOGO from '../_imgs/Logo';
import Image from '../_components/Image';
import TMA_BANNER from '../_imgs/quino.jpg';
import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import getApplication from '../_helpers/getApplication';
import cls from '../_helpers/cls';

const AuthLayout = ({
  onSubmit,
  children,
  validSlug,
  skipValid,
  hideFooter = false,
  getApplicationAssets,
}) => {
  const [logoUrl, setLogoUrl] = useState(null);
  const [bannerUrl, setBannerUrl] = useState(null);

  useEffect(() => {
    if (getApplicationAssets) {
      const { logo, banner } = getApplicationAssets;
      if (logo?.url) setLogoUrl(logo.url);
      if (banner?.url) setBannerUrl(banner.url);
    }
  }, [getApplicationAssets]);

  return (
    <div
      className={[styles.wrap, validSlug || skipValid ? styles.validSlug : null]
        .filter(Boolean)
        .join(' ')}
    >
      <div className={styles.form}>
        {(validSlug || skipValid) && (
          <Fragment>
            <form onSubmit={onSubmit}>
              <div className={styles['form-wrap']}>
                {logoUrl ? (
                  <Image src={logoUrl} alt="Logo" width={120} height={120} />
                ) : (
                  <>{TMA_LOGO}</>
                )}

                {children}
              </div>
            </form>

            {!hideFooter && (
              <footer>
                {logoUrl && (
                  <div className={styles.branding}>
                    {' '}
                    <div className={styles.logo}>{TMA_LOGO}</div>
                    TrainMyAthlete
                  </div>
                )}
                <div>
                  &copy; {new Date().getFullYear()} TrainMyAthlete Ltd
                  <span>|</span>
                  <a href="https://trainmyathlete.com/privacy-policy">
                    Privacy Policy
                  </a>
                </div>
              </footer>
            )}
          </Fragment>
        )}
      </div>

      <div className={cls(styles.img, { [styles.fade]: !bannerUrl })}>
        <Image
          key={bannerUrl || 'fallback'}
          src={bannerUrl || TMA_BANNER}
          alt="TrainMyAthlete"
        />
      </div>
    </div>
  );
};

export default compose(
  // Queries
  // =========================================================================

  // Is Valid Sub-domain
  // -------------------------------------------------------------------------

  graphql(
    gql`
      query IsValidSubdomain($slug: String!) {
        validSlug(slug: $slug)
      }
    `,
    {
      skip: ({ skipValid }) => skipValid,
      options: {
        variables: {
          slug: getApplication(),
        },
      },
      props: ({ data: { validSlug } }) => {
        if (['admin', 'register'].indexOf(getApplication()) > -1)
          validSlug = true;
        return { validSlug };
      },
    }
  ),

  graphql(
    gql`
      query getAppAssets($slug: String!) {
        getApplicationAssets(slug: $slug)
      }
    `,
    {
      options: () => ({
        variables: {
          slug: getApplication(),
        },
      }),
      props: ({ data: { getApplicationAssets, loading, error } }) => {
        if (loading) {
          console.log('Loading assets...');
        }
        if (error) {
          console.error('Error fetching application assets:', error);
        }
        return { getApplicationAssets };
      },
    }
  )
)(AuthLayout);
